import { createStore, createLogger } from "vuex";
// 数据持久化
import createPersistedstate from 'vuex-persistedstate'

// 导入模块
// import user from './modules/user'
// import cart from './modules/cart'
// import category from './modules/category'
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

// getters
import getters from './getters'

// 创建一个新的 store 实例
export default createStore({
    state(){
        return {
            info: 'hello'
        }
    },
    // 定义mutations，用于修改状态(同步)
    mutations:{
        updateInfo (state, payload) {
            state.info = payload
        }
    },
    // 定义actions，用于修改状态(异步)
    actions:{
        updateInfo(context,payload){
            // setTimeout(()=>{// 2秒后更新状态
            //     context.commit('updateInfo',payload)
            // },2000)
            context.commit('updateInfo',payload)
        }
    },
    // 定义一个getters
    // getters:{
    //     formatInfo(state){
    //         return state.info + ' Tom'
    //     },
    //     getToken (state) {
    //       return state.user.profile.token
    //     }
    // },
    getters:getters,
    // modules:{
    //     user,
    //     cart,
    //     category
    // },
    modules:modules,
    // 插件的存储方式：内部是调用了本地localstore存储在了本地
    plugins: [
      createPersistedstate({
        key: 'erabbit-client-pc-store',
        paths: ['user', 'cart']
      }),
      createLogger()
    ]
})
