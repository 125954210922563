<template>
  <!-- <router-view :key="$route.fullPath" /> -->
  <router-view/>
</template>

<script>
// import { useStore } from 'vuex'
import * as echarts from 'echarts'
import { provide } from 'vue'
export default {
  name: 'App',
  setup(){
    provide('echarts',echarts)//provide
  },
  // setup () {
  //   const store = useStore()
  //   const setValue = () => {
  //     store.commit('user/setId', 500)
  //   }
  //   const getValue = () => {
  //     console.log('value is ', store.state.user.profile.id)
  //   }
  //   return {
  //     setValue,
  //     getValue
  //   }
  // }
}
</script>

<style lang="less" scoped>
</style>
