export function mounted(el){
    //获取代码片段
    let code = el.querySelector('code.hljs')
    // let pre = document.querySelectorAll('pre')
    let pre = code.parentNode
    let html = code?.innerHTML
    let size = html.split('\n').length

    //插入行数
    let ul = document.createElement('ul')
    for (let i = 1; i <= size; i++) {
        let li = document.createElement('li')
        li.innerText = i + ''
        ul.appendChild(li)
    }

    ul.classList.add('hljs-code-number')
    // console.log('code.parentNode',code.parentNode.parentNode);

    
    // el.insertBefore(ul, pre)
    //获取父元素
    // if(document.getElementsByClassName('hljs-code-number').length == 0){
    //     el.insertBefore(ul, pre)
    // }else{
    //     code.parentNode.parentNode.removeChild(code.parentNode.parentNode.childNodes[0])
    // }

    //插入复制功能
    let copy = document.createElement('div')
    copy.classList.add('hljs-copy')
    copy.innerText = '复制代码'
    //添加点击事件
    copy.addEventListener('click', () => {
        //创建一个输入框
        let textarea = document.createElement('textarea')
        document.body.appendChild(textarea);
        textarea.setAttribute('readonly', 'readonly')
        textarea.value = code.innerText;
        textarea.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            copy.innerText = '复制成功'
        }
        document.body.removeChild(textarea);
    })

    pre.appendChild(copy)

    //鼠标移入显示复制按钮
    el.addEventListener('mouseout', () => {
        copy.innerText = '复制代码'
        // copy.style.display = "none"
    })
    el.addEventListener('mouseover', () => {
        // copy.style.display = "block"
    })
}