import { createApp} from 'vue'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/index.less'
import '@/assets/icon-font/iconfont.css'
import lodash from 'lodash'
import myDropOff from '@/components/my-drop-off/my-drop-off.vue'
// highlight 的样式，依赖包，组件
import 'highlight.js/styles/atom-one-dark.css'
// import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

// import 'highlight.js/styles/stackoverflow-light.css'
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
// import hljsVuePlugin from "@highlightjs/vue-plugin";
hljs.registerLanguage('javascript', javascript);

// 引入全局指令
import { directive } from './directives/index'

// 将所有ui组件全局注册
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 全局注册分页组件
app.config.globalProperties.$lodash = lodash;

app.config.globalProperties.$scrollTo = (x = 0, y = 0, type = 'smooth') => {
    window.scrollTo({
        top: x,
        left: y,
        behavior: type // 滚动行为：smooth平滑滚动，instant瞬间滚动，默认值auto，等同于instant
    })
}
// 注册全局组件
app.component('myDropOff', myDropOff);

// 全局挂载

// 导入全局组件
// import XtxSkeleton from '@/components/Skeleton'
// 导入全局指令
// import DirectivesPlugin from '@/directives'

// createApp(App)
// // .use(XtxSkeleton)
// // .use(DirectivesPlugin)
// .use(router)
// .use(store)
// .use(ElementPlus)
// .mount('#app')
directive(app)
app.use(router)
app.use(store)
app.use(hljsVuePlugin)
app.use(ElementPlus, { locale }).mount('#app')

